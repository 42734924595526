import {createGlobalStyle} from 'styled-components'
import tw, {styled} from 'twin.macro'

export const Constants = {
  colors: {
    mainPurple: '#8363a1',
    mainBlue: '#74a8c3',
  }
}


export const GlobalStyle = createGlobalStyle`
  html {
    /* width: 100vh; */
    direction: rtl; /* right-to-left for Arabic */
    height: 100vh;
    background-color: #fff; 
    background-image: ${`linear-gradient(135deg, ${Constants.colors.mainPurple} 0%, ${Constants.colors.mainBlue} 100%)`};
  }
`

export const Box = styled.div`
  ${tw`flex flex-col justify-center items-center h-full font-sans bg-white w-full max-w-lg px-6 py-3`}
`

export const FormComponent = tw.form`
  w-full flex flex-col justify-center items-center
`

export const Text = tw.span`text-center text-base text-gray-700 mb-2`
