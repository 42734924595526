import React from 'react'
import tw, {styled} from 'twin.macro'
import { Helmet } from 'react-helmet'
import {GlobalStyle} from './style'
import TwinGlobalStyles from './GlobalStyles'

const Container = styled.div`
  ${tw`flex flex-col justify-center items-center h-full font-sans my-4`}
`

const MetaTags = () => {
  const content = {
    title: 'رسالة الى المستقبل!',
    description: ' تواصل مع نفسك بالمستقبل، أكتب طموحاتك، أهدافك، ماذا ستحقق خلال ال٦ أشهر.. السنة.. الثلاث سنوات القادمة؟',
    keywords: 'رسالة, مستقبل, اكتب, ارسل, المستقبل',
    url: 'https://mustqbl.me',
    image: 'https://mustqbl.me/thumb.png'
  }

  return (
  <Helmet>
    <title>{content.title}</title>
    <meta name="description" content={content.description} />
    <meta name="keywords" content={content.keywords} />
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    {/* OpenGraph tags */}
    <meta property="og:url" content={content.url} />
    <meta property="og:title" content={content.title} />
    <meta property="og:description" content={content.description} />
    <meta property="og:image" content={content.image} />

    {/* twitter tags */}
    <meta name="twitter:title" content={content.title} />
    <meta name="twitter:description" content={content.description} />
    <meta name="twitter:image" content={content.image} />
    <meta name="twitter:card" content="summary_large_image" />


  </Helmet>)}

const Layout = ({ children, ...rest }) => (
  <Container {...rest}>
    <MetaTags />
    <GlobalStyle/>
    <TwinGlobalStyles />
    {children}
  </Container>
)

export default Layout
